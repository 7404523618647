
import React, { useEffect, useState, useMemo } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Auth, API } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import RegisterLayout from "views/examples/Register.js";
import { Modal } from "react-bootstrap";

const Login = () => {
  const [target, setTarget] = useState(false);
  const [register, showRegister] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const handleClick = async () => {
    // Auth.forgotPasswordSubmit(email, "072946", "MaheshDigi@2020")
    // .then(data => console.log(data))
    // .catch(err => console.log(err));
    setTarget(true)
    console.log('handleClick sign in', email, password);
    Auth.signIn(email, password)
      .then(user => {
        console.log('successful sign in', user);
        setTarget(false)
        if (email === 'varun.garg@digitalglyde.com' || email === 'mahesh.maharana@wyest.com') {
          setShowModal(true)
        } else {
          history.push('/admin/tables');
        }
      })
      .catch((error) => {
        setTarget(false)
        console.log(error);
        alert("Please Enter Right Email or Password !");
      })

  }
  const goToDashboard = async () => {
    history.push('/admin/tables');
  }
  return (
    <>
      <Modal show={showModal}>
        {/* <Modal.Header closeButton>
          </Modal.Header> */}
        <Modal.Body>
          {/* <div className="form-group fv-plugins-icon-container"> */}
          Please select an option from below
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
              </Button>
          <Button variant="primary" type="submit" onClick={() =>goToDashboard()}>
            Dashboard
              </Button>
          <Button variant="primary" type="submit" onClick={() =>{ showRegister(true); setShowModal(false)}}>
            Register
              </Button>
        </Modal.Footer>
      </Modal>
      {register ? <RegisterLayout /> :
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  {target? <Button className="my-4" color="primary" type="button" >
                    Please Wait...
                </Button>:
                  <Button className="my-4" color="primary" type="button" onClick={() => handleClick()}>
                    Sign in
                </Button>}
                </div>
              </Form>
            </CardBody>
          </Card>
          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => { e.preventDefault(); showRegister(true) }}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
        </Col>}
    </>
  );
};
// export default withAuthenticator(Login)
export default Login;
