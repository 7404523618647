/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ce4bdc11-3e17-4b09-87a5-6fd935437d4f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HCTrs80A8",
    "aws_user_pools_web_client_id": "7ohruvd8me4t5p55m5b63deamg",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "wssantigen",
            "endpoint": "https://iyxhj127ce.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
