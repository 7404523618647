
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
// import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Collapse from '@material-ui/core/Collapse';
import { useHistory, useLocation } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Badge, Dropdown,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  //Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/TableHeader.js";
import { Auth, API } from 'aws-amplify';
import _ from 'lodash';

// import React from 'react';
import PropTypes from 'prop-types';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return -1;
}


const headCells = [
  { id: 'client', label: 'Client' },
  { id: 'employee_name', label: 'Employee Name' },
  { id: 'collection_date', label: 'Collection Date' },
  { id: 'results', label: 'Result' },
  { id: 'email', label: 'Email' },
  { id: 'actions', label: 'Actions', width: 70 }
  // { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: 700 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <>

    </>
    // <Toolbar
    //   className={clsx(classes.root, {
    //     [classes.highlight]: numSelected > 0,
    //   })}
    // >
    //   {numSelected > 0 ? (
    //     <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
    //       {numSelected} selected
    //     </Typography>
    //   ) : (
    //     <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
    //       Nutrition
    //     </Typography>
    //   )}

    //   {numSelected > 0 ? (
    //     <Tooltip title="Delete">
    //       <IconButton aria-label="delete">
    //         <DeleteIcon />
    //       </IconButton>
    //     </Tooltip>
    //   ) : (
    //     <Tooltip title="Filter list">
    //       <IconButton aria-label="filter list">
    //         <FilterListIcon />
    //       </IconButton>
    //     </Tooltip>
    //   )}
    // </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const Tables = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('testDate');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [rowItem, setItem] = React.useState();
  const [docDelete, setDocDelete] = React.useState(false);

  const [tData, setTData] = useState([]);
  const [tDataTemp, setTDataTemp] = useState([]);
  const [cVal, setC] = useState(false);
  const [value, setValue] = useState(false);
  const [showNationality, setShowNationality] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const [state, setState] = useState({
    "client": "",
    "comp": "",
    "email": "",
    "fileName": "",
    "collectionDate": "",
    "dob": "",
    "Guid": "",
    "name": "",
    "nationality": "",
    "passport": "",
    "passid": "",
    "sample": "",
    "result": false,
    "sex": "",
    "testDate": "",
    "URL": ""
  });
  const [showAdd, setShowAdd] = useState(false);

  useEffect(async () => {
    let authUser = (await Auth.currentUserInfo())
    console.log("auth user ", authUser)
    if (!authUser) {
      history.push("/")
    }
    setState({
      "client": "",
      "comp":"",
      "email": "",
      "fileName": "",
      "collectionDate": "",
      "dob": "",
      "Guid": "",
      "name": "",
      "nationality": "",
      "passport": "",
      "passid":"",
      "sample":"",
      "result": false,
      "sex": "",
      "testDate": "",
      "URL": ""
    })
    getData()
  }, [])
  const getData = async () => {
    const apiName = 'wssantigen';
    const path = '/items';
    const myInit = { // OPTIONAL
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },

    };
    console.log("Token is ", myInit)
    await API
      .get(apiName, path, myInit)
      .then(response => {
        let resData = []
        let resArr = []
        let sortData = response.data.sort(function (a, b) {
          return moment(a.collectionDate,'MMM DDD YYYY hh:mm a').format('X')-moment(b.collectionDate,'MMM DDD YYYY hh:mm a').format('X');
          // return Number(new Date(a.collectionDate)) - Number(new Date(b.collectionDate));
        });
        console.log("sortData is ", sortData)
        _.each(sortData, (resDat, i) => {
          resArr.push({ ...resDat, id: i })
        })
        console.log("Data is ", resArr)
        setTimeout(() => {
          setDocDelete(false);
        }, 3000);
        setTData(resArr)
        setTDataTemp(resArr)
        setShowAdd(false)
      })
      .catch(error => {
        // console.log(error.response);
      });
  }
  console.log("tData", tData)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const setData = (name, e) => {
    // if (e && e.target && e.target.value) {
      if (name == "result") {
        state[name] = (e.target.value == "true") ? true : false;
      } else {
        state[name] = e.target.value;
      }
      setC(!cVal);
    // }
  }
  const saveData = () => {
    if(!showNationality){
      state['nationality']=''
    }
    console.log("save data is ::", state)
    let options = {
      method: 'POST',
      //      "headers": {
      //   "content-type": "application/json",
      //   "cache-control": "no-cache",
      //   "postman-token": "209a7b45-89b0-f2be-152b-a1a1a07dc00f"
      // },
      headers: {
        'Content-Type':
          'application/json;charset=utf-8'
      },
      body: JSON.stringify(state)
    }

    fetch("https://odooappantigen.digitalglyde.com/generatePdf", options)
      .then((response) => response.json())
      .then(data => {
        console.log("PDF Generated", data);
        setState({
          "client": "",
          "comp": "",
          "email": "",
          "fileName": "",
          "collectionDate": "",
          "dob": "",
          "Guid": "",
          "name": "",
          "nationality": "",
          "passport": "",
          "passid": "",
          "sample": "",
          "result": false,
          "sex": "",
          "testDate": "",
          "URL": ""
        })
        setTimeout(() => {
          getData()
        }, 2000);
        // window.location.reload()
      }).catch((error) => {
        console.log("error  :: ", error);
      })
  }

  const setFieldValueText = (e) => {
    console.log("hgel")

    if (e.target.value.length > 0) {
      setValue(true);
      console.log("e", e.target.value, tData)
      let datArr = tData.filter(val =>
        (val.client && val.client.toLowerCase().includes(e.target.value.toLowerCase()))
        || (val.result ? "positive".includes(e.target.value) : "negative".includes(e.target.value))
        || (val.name && val.name.toLowerCase().toString().includes(e.target.value.toLowerCase()))
      );
      console.log("datArr", datArr);
      setTData(datArr)
      setRefresh(!refresh)
    } else {
      setTData(tDataTemp)
      setValue(false);
      setRefresh(!refresh)
    }
  }
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const setViewDoc = (row) => {
    window.open(row.URL, '_blank');
  }
  const sendEmail = (row) => {

    let options = {
      method: 'POST',
      headers: {
        'Content-Type':
          'application/json;charset=utf-8'
      },
      body: JSON.stringify(row)
    }
    fetch("https://odooappantigen.digitalglyde.com/sendEmail/", options)
      .then((response) => response.json())
      .then(data => {
        console.log("data response", data.recordset)
      })

  }
  const showNewAdd = () =>{
    setShowNationality(false)
    setState({
      "client": "",
      "comp": "",
      "email": "",
      "fileName": "",
      "collectionDate": "",
      "dob": "",
      "Guid": "",
      "name": "",
      "nationality": "",
      "passport": "",
      "passid": "",
      "sample": "",
      "result": false,
      "sex": "",
      "testDate": "",
      "URL": ""
    })
    setShowAdd(true)
  }
  const deleteItem = async (row) => {
    console.log("row item ", row)
    let options = {
      method: 'POST',
      headers: {
        'Content-Type':
          'application/json;charset=utf-8'
      },
      body: JSON.stringify(row)
    }
    await fetch("https://odooappantigen.digitalglyde.com/deleteItems/", options)
      .then((response) => response.json())
      .then(data => {
        console.log("data response", data)
      })

    const apiName = 'wssantigen';
    const path = '/items';
    const myInitDelete = { // OPTIONAL
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body:
      {
        "Key": {
          "Guid": row.Guid,
        },
        "TableName": "wssdocumentantigen"
      }
    }
    await API
      .del(apiName, path, myInitDelete)
      .then(response => {
        setDocDelete(true);
        getData();
        setOpen(false)
      })
      .catch(error => {
        console.log("error",error);
      });
  }
  console.log("state is ",showNationality,state)
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure, you want to Confirm Connection for the selected records?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteItem(rowItem)} color="primary">
            Confirm
            </Button>
          <Button onClick={() => setOpen(false)} color="danger" autoFocus>
            Close
            </Button>
        </DialogActions>
      </Dialog>
      <Header />
      {/* Page content */}
      <Container className="mt--7" >
        {/* Table */}
        {showAdd ?
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div style={{ flexDirection: "row" }}>
                    <Row>
                      <Col md="10">
                        <h3 >Add New Result</h3>
                      </Col>
                      <Col md="1">
                        <Button color="danger" type="button" onClick={() => setShowAdd(false)}>
                          Cancel
                        </Button>
                      </Col>

                      <Col md="1">
                        <Button color="primary" type="button" onClick={() => saveData()}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                  </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{ flexDirection: "row" }}
                            >
                              <Input type="select" className="form-control-alternative"
                                id="input-username"
                                placeholder="Gender"
                                //type="text"
                                onChange={(e) => setData("comp", e)}>
                                <option value="">-- Select Company/Vessel --</option>
                                <option value="Company">Company</option>
                                <option value="Vessel">Vessel</option>
                              </Input>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Client"
                              onChange={(e) => setData("client", e)}
                              type="text"
                            />
                            {/* <Input type="select" className="form-control-alternative"
                              id="input-username"
                              placeholder="Employee Name"
                              //type="text"
                              onChange={(e) => setData("client", e)}>
                              <option value="">-- Select --</option>
                              {tData && tData.length > 0 && tData.filter((v, i, a) => a.findIndex(t => (t.client === v.client)) === i).map((row) =>
                                <option value={row.client}>{row.client}</option>
                              )}
                            </Input> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Employee Name
                          </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Employee Name"
                              type="text"
                              onChange={(e) => setData("name", e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Sex
                          </label>
                            <Input type="select" className="form-control-alternative"
                              id="input-username"
                              placeholder="Gender"
                              //type="text"
                              onChange={(e) => setData("sex", e)}>
                              <option value="">-- Select --</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              DOB
                          </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Date of Birth"
                              onChange={(e) => setData("dob", e)}
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                          </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Enter Email"
                              onChange={(e) => setData("email", e)}
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Nationality    
                          </label>
                            <input type="checkbox"
                              style={{marginLeft:5}}
                              defaultChecked={showNationality}
                              value={showNationality}
                              onChange={()=>setShowNationality(!showNationality)}
                            />
                            {showNationality && <Input
                              className="form-control-alternative"
                              value={state.nationality}
                              id="input-first-name"
                              placeholder="Nationality"
                              onChange={(e) => setData("nationality", e)}
                              type="text"
                            />}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              <Input type="select" className="form-control-alternative"
                                id="input-username"
                                placeholder="Gender"
                                //type="text"
                                onChange={(e) => setData("passid", e)}>
                                <option value="">-- Select Passport/ID --</option>
                                <option value="Passport">Passport</option>
                                <option value="ID">ID</option>
                              </Input>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Passport/ID"
                              onChange={(e) => setData("passport", e)}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Collection Date
                          </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="Last name"
                              onChange={(e) => setData("collectionDate", e)}
                              type="datetime-local"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Sample Type
                          </label>
                            <Input type="select" className="form-control-alternative"
                              id="input-username"
                              placeholder="Gender"
                              //type="text"
                              onChange={(e) => setData("sample", e)}>
                              <option value="">-- Select Sample Type --</option>
                              <option value="Nasal Swab">Nasal Swab</option>
                              <option value="Nasopharyngeal Swab">Nasopharyngeal Swab</option>
                            </Input>
                            {/* <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="Last name"
                              onChange={(e) => setData("testDate", e)}
                              type="datetime-local"
                            /> */}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Result
                          </label>
                            <Input type="select" className="form-control-alternative"
                              id="input-username"
                              placeholder="Result"
                              //type="text"
                              onChange={(e) => setData("result", e)}>
                              <option value="">-- Select --</option>
                              <option value={true}>POSITIVE</option>
                              <option value={false}>NEGATIVE</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
                {/* <CardFooter className="py-4">
                  

                </CardFooter> */}
              </Card>
            </div>
          </Row> :
          <Row>
            <div className="col">
              <Card >
                <CardHeader >
                  <div>
                    <Row>
                      <Col lg="4">
                        <h3 >Client List</h3>
                      </Col>
                      <Col lg="4">
                        <input
                          type="text"
                          className="form-control"
                          name="searchText"
                          placeholder="Search by Employee Name OR Client"
                          //onBlur={handleBlur}
                          //value={values.searchText}
                          style={{ width: 400 }}
                          onChange={(e) => {
                            setFieldValueText(e);
                            // handleSubmit();
                          }}
                        />
                      </Col>
                      <Col lg="2"></Col>
                      <Col lg="2">
                        <Button color="primary" type="button" onClick={() => showNewAdd()} style={{ marginLeft: 40 }}>
                          Add New
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <Collapse in={docDelete}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setDocDelete(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Document Deleted successfully!
                    </Alert>
                </Collapse>
                <CardBody>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <EnhancedTableToolbar numSelected={selected.length} />
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={tData.length}
                          />
                          <TableBody>
                            {stableSort(tData, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.Guid)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell align="left">{row.client}</TableCell>
                                    <TableCell align="left">{row.employeeName ? row.employeeName : row.name ? row.name : ""}</TableCell>
                                    <TableCell align="left">{row.collectionDate}</TableCell>
                                    <TableCell align="left">{row.result ? "POSITIVE" : "NEGATIVE"}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    {/* <TableCell>
                                      <OverlayTrigger
                                        overlay={<Tooltip id="products-edit-tooltip">View Result PDF</Tooltip>}
                                      >
                                        <a href={row.URL} target="_blank"
                                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        // onClick={() => setViewDoc(row)}
                                        >
                                          <i class="fa fa-paperclip" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                                        </a>
                                      </OverlayTrigger>
                                    </TableCell> */}
                                    <TableCell className="text-center">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) => setViewDoc(row)}
                                          >
                                            <i class="fa fa-eye" aria-hidden="true" style={{ fontWeight: "normal", cursor: 'pointer' }}> View PDF</i>
                                          </DropdownItem>
                                          {/* <DropdownItem
                                            href="#pablo"
                                            onClick={(e) => {e.preventDefault(); sendEmail(row)}}
                                          >
                                            <i class="fa fa-envelope" aria-hidden="true" style={{ fontWeight:"normal" , cursor: 'pointer' }}> Send Email</i>
                                          </DropdownItem> */}
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={() => { setOpen(true); setItem(row) }}
                                          >
                                            <i class="fa fa-trash-alt" aria-hidden="true" style={{ fontWeight: "normal", cursor: 'pointer' }}> Delete</i>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>}

      </Container>
    </>
  );
};

export default Tables;
